import React from "react";
import { Link } from "gatsby";

import Logo from "../images/logo.png";
import moreArrowDark from "../images/moreArrowDark.png";
import angleRight from "../images/angleRight.png";
import academiesLogo from "../images/academiesLogo.png";
import craneLogo from "../images/craneLogo.png";
import ugaLogo from "../images/ugaLogo.png";
import researchLogo from "../images/researchLogo.png";
import athensLogo from "../images/athensLogo.png";
import adirondackLogo from "../images/adirondackLogo.png";
import mobileMenu from "../images/mobileMenu.svg";
import close from "../images/close.png";

import HeaderStyles from "./Header.module.css";

class Header extends React.Component {
  state = { openMenu: false };
  componentDidMount() {
    // alert(this.props.newRef);
    // console.log(this.props.location);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // console.log(this.props.location);
    }
  }
  closeMenu=()=>{
    this.setState({ openMenu: false })
  }
  hoverLiEnter = e => {
    e.target.style.backgroundColor = "#efefef";
  };
  hoverLiExit = e => {
    e.target.style.backgroundColor = "inherit";
  };

  render() {
    let logo;
    switch (this.props.location.pathname) {
      case "/programs/tss-uga":
        logo = `${ugaLogo}`;
        break;
      case "/programs/tss-crane":
        logo = `${craneLogo}`;
        break;
      case "/programs/tss-research":
        logo = `${researchLogo}`;
        break;

      case "/programs/tss-k12":
        logo = `${academiesLogo}`;
        break;
      case "/programs/tss-athens":
        logo = `${athensLogo}`;
        break;
      case "/programs/tss-adirondack":
        logo = `${adirondackLogo}`;
        break;

      default:
        logo = `${Logo}`;
    }
    return (
      <header>
       
        <Link to="/">
          <img className={HeaderStyles.logo} src={logo}></img>
        </Link>
        <img
          id={HeaderStyles.mobileMenu}
          src={this.state.openMenu === true ? close : mobileMenu}
          onClick={() => {
            if (this.state.openMenu === true) {
              this.setState({ openMenu: false });
            } else {
              this.setState({ openMenu: true });
            }
          }}
        ></img>
        <nav id={HeaderStyles.nav}>
          <ul>
            <li>
              <Link className={HeaderStyles.link} to="/">
                home
              </Link>
            </li>
            <li
              // onClick={this.props.toggleAboutOpen}
              onMouseEnter={this.props.toggleAboutOpen}
              onMouseLeave={this.props.toggleAboutOpen}
            >
              <span>
                about <img src={moreArrowDark}></img>
              </span>

              <div
                className={
                  this.props.isAboutDDOpen === true
                    ? HeaderStyles.active
                    : HeaderStyles.notActive
                }
              >
                <ul>
                  <li
                    onMouseEnter={this.hoverLiEnter}
                    onMouseLeave={this.hoverLiExit}
                  >
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li
                    onMouseEnter={this.hoverLiEnter}
                    onMouseLeave={this.hoverLiExit}
                  >
                    <Link to="/about-marie">About Marie</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li
              onMouseEnter={this.props.toggleProgramOpen}
              onMouseLeave={this.props.toggleProgramOpen}
            >
              <span>
                programs <img src={moreArrowDark}></img>
              </span>
              <div
                className={
                  this.props.isProgramDDOpen === true
                    ? HeaderStyles.active
                    : HeaderStyles.notActive
                }
              >
                <ul>
                  <li
                    id={HeaderStyles.trainingLi}
                    onMouseEnter={this.props.toggleTrainingOpen}
                    onMouseLeave={this.props.toggleTrainingOpen}
                    style={
                      this.props.isTrainingDDOpen === true
                        ? { backgroundColor: "#efefef" }
                        : null
                    }
                  >
                    <span>
                      TSS Training <img src={angleRight}></img>
                    </span>
                    <div
                      id={HeaderStyles.trainingDD}
                      className={
                        this.props.isTrainingDDOpen === true
                          ? HeaderStyles.active
                          : HeaderStyles.notActive
                      }
                    >
                      <ul style={{ marginLeft: "1px" }}>
                        <li
                          onMouseEnter={this.hoverLiEnter}
                          onMouseLeave={this.hoverLiExit}
                        >
                          <Link to="/programs/tss-k12">TSS K-12</Link>
                        </li>
                        <li
                          onMouseEnter={this.hoverLiEnter}
                          onMouseLeave={this.hoverLiExit}
                        >
                          <Link to="/programs/tss-uga">TSS UGA</Link>
                        </li>
                        <li
                          onMouseEnter={this.hoverLiEnter}
                          onMouseLeave={this.hoverLiExit}
                        >
                          <Link to="/programs/tss-onny">TSS Onny</Link>
                        </li>
                        <li
                          onMouseEnter={this.hoverLiEnter}
                          onMouseLeave={this.hoverLiExit}
                        >
                          <Link to="/programs/tss-crane">TSS Crane</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    id={HeaderStyles.outreachLi}
                    onMouseEnter={this.props.toggleOutreachOpen}
                    onMouseLeave={this.props.toggleOutreachOpen}
                    style={
                      this.props.isOutreachDDOpen === true
                        ? { backgroundColor: "#efefef" }
                        : null
                    }
                  >
                    <span>
                      TSS Outreach <img src={angleRight}></img>
                    </span>
                    <div
                      id={HeaderStyles.outreachDD}
                      className={
                        this.props.isOutreachDDOpen === true
                          ? HeaderStyles.active
                          : HeaderStyles.notActive
                      }
                    >
                      <ul style={{ marginLeft: "1px" }}>
                        <li
                          onMouseEnter={this.hoverLiEnter}
                          onMouseLeave={this.hoverLiExit}
                        >
                          <Link to="/programs/tss-athens">TSS Athens</Link>
                        </li>
                        <li
                          onMouseEnter={this.hoverLiEnter}
                          onMouseLeave={this.hoverLiExit}
                        >
                          <Link to="/programs/tss-adirondack">
                            TSS Adirondacks
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li
                    onMouseEnter={this.hoverLiEnter}
                    onMouseLeave={this.hoverLiExit}
                  >
                    <Link to="/programs/tss-research">TSS Research</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to="/our-team">our team</Link>
            </li>
            <li
              onMouseEnter={this.props.toggleHappeningsOpen}
              onMouseLeave={this.props.toggleHappeningsOpen}
            >
              <span>
                Happenings <img src={moreArrowDark}></img>
              </span>

              <div
                className={
                  this.props.isHappeningsDDOpen === true
                    ? HeaderStyles.active
                    : HeaderStyles.notActive
                }
              >
                <ul>
                  <li
                    onMouseEnter={this.hoverLiEnter}
                    onMouseLeave={this.hoverLiExit}
                  >
                    <Link to="/events">Events</Link>
                  </li>
                  <li
                    onMouseEnter={this.hoverLiEnter}
                    onMouseLeave={this.hoverLiExit}
                  >
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li
                    onMouseEnter={this.hoverLiEnter}
                    onMouseLeave={this.hoverLiExit}
                  >
                    <Link to="/poets-corner">POETS' Corner</Link>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <Link to="/contact-us">contact</Link>
            </li>
            <li>
              <Link className={HeaderStyles.donate} to="/donate">
                donate
              </Link>
            </li>
          </ul>
        </nav>
        <div
          className={HeaderStyles.mobileMenuContainer}
          style={
            this.state.openMenu === true
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <nav>
            <ul>
              <li onClick={this.closeMenu}>
                <Link className={HeaderStyles.link} to="/">
                  home
                </Link>
              </li>
              <li
                // onClick={this.props.toggleAboutOpen}
                onClick={this.props.toggleAboutOpen}
              >
                <span>
                  about <img src={moreArrowDark}></img>
                </span>
                {this.props.isAboutDDOpen ? (
                  <ul className={HeaderStyles.aboutDD}>
                    <li onClick={this.closeMenu}>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li onClick={this.closeMenu}>
                      <Link to="/about-marie">About Marie</Link>
                    </li>
                  </ul>
                ) : null}
              </li>
              <li onClick={this.props.toggleProgramOpen}>
                <span>
                  programs <img src={moreArrowDark}></img>
                </span>
                {this.props.isProgramDDOpen ? (
                  <ul className={HeaderStyles.programsDD}>
                    <li onClick={this.closeMenu}>
                      <Link to="/programs/tss-k12">TSS K-12</Link>
                    </li>
                    <li onClick={this.closeMenu}>
                      <Link to="/programs/tss-uga">TSS UGA</Link>
                    </li>
                    <li onClick={this.closeMenu}>
                      <Link to="/programs/tss-onny">TSS Onny</Link>
                    </li>
                    <li onClick={this.closeMenu}>
                      <Link to="/programs/tss-crane">TSS Crane</Link>
                    </li>

                    <li onClick={this.closeMenu}>
                      <Link to="/programs/tss-athens">TSS Athens</Link>
                    </li>
                    <li onClick={this.closeMenu}>
                      <Link to="/programs/tss-adirondack">TSS Adirondacks</Link>
                    </li>

                    <li onClick={this.closeMenu}>
                      <Link to="/programs/tss-research">TSS Research</Link>
                    </li>
                  </ul>
                ) : null}
              </li>
              <li onClick={this.closeMenu}>
                <Link to="/our-team">our team</Link>
              </li>
              <li onClick={this.props.toggleHappeningsOpen}>
                <span>
                  Happenings <img src={moreArrowDark}></img>
                </span>
                {this.props.isHappeningsDDOpen ? (
                  <ul className={HeaderStyles.aboutDD}>
                    <li onClick={this.closeMenu}>
                      <Link to="/events">Events</Link>
                    </li>
                    <li onClick={this.closeMenu}>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li onClick={this.closeMenu}>
                      <Link to="/poets-corner">POETS' Corner</Link>
                    </li>
                  </ul>
                ) : null}
              </li>

              <li onClick={this.closeMenu}>
                <Link to="/contact-us">contact</Link>
              </li>
              <li onClick={this.closeMenu}>
                <Link className={HeaderStyles.donate} to="/donate">
                  donate
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}
export default Header;
