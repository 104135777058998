// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-marie-js": () => import("./../../../src/pages/about-marie.js" /* webpackChunkName: "component---src-pages-about-marie-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-audio-js": () => import("./../../../src/pages/audio.js" /* webpackChunkName: "component---src-pages-audio-js" */),
  "component---src-pages-bios-js": () => import("./../../../src/pages/bios.js" /* webpackChunkName: "component---src-pages-bios-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-images-js": () => import("./../../../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-past-events-js": () => import("./../../../src/pages/past-events.js" /* webpackChunkName: "component---src-pages-past-events-js" */),
  "component---src-pages-poets-corner-js": () => import("./../../../src/pages/poets-corner.js" /* webpackChunkName: "component---src-pages-poets-corner-js" */),
  "component---src-pages-programs-research-janus-hypothesis-js": () => import("./../../../src/pages/programs/research/janus-hypothesis.js" /* webpackChunkName: "component---src-pages-programs-research-janus-hypothesis-js" */),
  "component---src-pages-programs-research-our-research-js": () => import("./../../../src/pages/programs/research/our-research.js" /* webpackChunkName: "component---src-pages-programs-research-our-research-js" */),
  "component---src-pages-programs-tss-adirondack-js": () => import("./../../../src/pages/programs/tss-adirondack.js" /* webpackChunkName: "component---src-pages-programs-tss-adirondack-js" */),
  "component---src-pages-programs-tss-athens-js": () => import("./../../../src/pages/programs/tss-athens.js" /* webpackChunkName: "component---src-pages-programs-tss-athens-js" */),
  "component---src-pages-programs-tss-crane-js": () => import("./../../../src/pages/programs/tss-crane.js" /* webpackChunkName: "component---src-pages-programs-tss-crane-js" */),
  "component---src-pages-programs-tss-k-12-js": () => import("./../../../src/pages/programs/tss-k12.js" /* webpackChunkName: "component---src-pages-programs-tss-k-12-js" */),
  "component---src-pages-programs-tss-onny-js": () => import("./../../../src/pages/programs/tss-onny.js" /* webpackChunkName: "component---src-pages-programs-tss-onny-js" */),
  "component---src-pages-programs-tss-research-js": () => import("./../../../src/pages/programs/tss-research.js" /* webpackChunkName: "component---src-pages-programs-tss-research-js" */),
  "component---src-pages-programs-tss-uga-js": () => import("./../../../src/pages/programs/tss-uga.js" /* webpackChunkName: "component---src-pages-programs-tss-uga-js" */),
  "component---src-pages-the-gift-of-alzheimers-js": () => import("./../../../src/pages/the-gift-of-alzheimers.js" /* webpackChunkName: "component---src-pages-the-gift-of-alzheimers-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-website-links-js": () => import("./../../../src/pages/website-links.js" /* webpackChunkName: "component---src-pages-website-links-js" */),
  "component---src-templates-bio-js": () => import("./../../../src/templates/bio.js" /* webpackChunkName: "component---src-templates-bio-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

