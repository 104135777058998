import React from "react";

import Header from "./Header";
import Footer from "./Footer";

import "../styles/styles.css";
import LayoutStyles from "./layout.module.css";

class Layout extends React.Component {
  container = React.createRef();
  state = {
    aboutOpen: false,
    programOpen: false,
    happeningsOpen: false,
    trainingOpen: false,
    outreachOpen:false
  };

  // componentDidUpdate(prevProps) {
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     this.setState(
  //       {
  //         aboutOpen: false,
  //         programOpen: false,
  //         happeningsOpen: false,
  //       },
  //       () => {
  //         console.log(this.props.location.pathname);
  //         console.log(prevProps.location.pathname);
  //       }
  //     );
  //   }
  // }
  toggleAboutOpen = e => {
    this.setState({ aboutOpen: !this.state.aboutOpen });
  };
  toggleProgramOpen = e => {
    this.setState({ programOpen: !this.state.programOpen });
  };
  toggleHappeningsOpen = e => {
    this.setState({ happeningsOpen: !this.state.happeningsOpen });
  };
  toggleTrainingOpen = e => {
    this.setState({ trainingOpen: !this.state.trainingOpen });
  };
  toggleOutreachOpen = e => {
    this.setState({ outreachOpen: !this.state.outreachOpen });
  };

  
  clickedOutside = () => {
    this.setState({
      aboutOpen: false,
      programOpen: false,
      happeningsOpen: false,
    });
  };
  render() {
    return (
      <div ref={this.container} className={LayoutStyles.container}>
        <div className={LayoutStyles.content}>
          <Header
            location={this.props.location}
            newRef={this.state.container}
            isAboutDDOpen={this.state.aboutOpen}
            isProgramDDOpen={this.state.programOpen}
            isHappeningsDDOpen={this.state.happeningsOpen}
            isTrainingDDOpen={this.state.trainingOpen}
            isOutreachDDOpen={this.state.outreachOpen}
            toggleAboutOpen={this.toggleAboutOpen}
            toggleProgramOpen={this.toggleProgramOpen}
            toggleHappeningsOpen={this.toggleHappeningsOpen}
            toggleTrainingOpen={this.toggleTrainingOpen}
            clickedOutside={this.clickedOutside}
            toggleOutreachOpen={this.toggleOutreachOpen}
          />
          {this.props.children}
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
export default Layout;
