import React from "react";
import { Link } from "gatsby";

import FooterStyles from "./Footer.module.css";

import MarkerIcon from "../images/locationMarker.png";
import PhoneIcon from "../images/phone.png";
import EmailIcon from "../images/emailWhite.png";

import caretDown from "../images/caretDown.svg";
import caretUp from "../images/caretUp.svg";

class Footer extends React.Component {
  state = {
    isArchivesPushed: false,
  };
  archiveClicked = () => {
    this.setState({ isArchivesPushed: !this.state.isArchivesPushed });
  };
  render() {
    return (
      <footer>
        <div className={FooterStyles.footerDivOuter}>
          <div className={FooterStyles.footerDivInner}>
            <h3>Links</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About</Link>
              </li>
              <li>
                <Link to="/poets-corner">Poets' Corner</Link>
              </li>
              <li>
                <Link to="/our-team">Our Team</Link>
              </li>
              <li>
                <Link to="/events">Events</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li
                onClick={this.archiveClicked}
                className={FooterStyles.archiveContainer}
              >
                <span>
                  Archives{" "}
                  <img
                    src={
                      this.state.isArchivesPushed === true ? caretUp : caretDown
                    }
                  />
                </span>
                {this.state.isArchivesPushed === true ? (
                  <ul>
                    <li>
                      <Link to="/audio">Audios</Link>
                    </li>
                    <li>
                      <Link to="/bios">Bios</Link>
                    </li>
                    <li>
                      <Link to="/past-events">Past Events</Link>
                    </li>
                    <li>
                      <Link to="/images">Photos</Link>
                    </li>
                    <li>
                      <Link to="/videos">Videos</Link>
                    </li>

                    <li>
                      <Link to="/website-links">Website Links</Link>
                    </li>
                  </ul>
                ) : null}
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
              <li>
                <Link to="/donate">Donate</Link>
              </li>
            </ul>
          </div>
          <div className={FooterStyles.footerDivInner}>
            <h3>Programs</h3>
            <ul id={FooterStyles.programUL}>
              <li>
                <span id={FooterStyles.span}>TSS Training</span>
              </li>
              <li>
                <Link to="/programs/tss-uga">TSS UGA</Link>
              </li>
              <li>
                <Link to="/programs/tss-k12">TSS K-12 Academies of music</Link>
              </li>
              <li>
                <Link to="/programs/tss-onny">TSS ONNY</Link>
              </li>
              <li>
                <Link to="/programs/tss-crane">TSS Crane</Link>
              </li>
              <li>
                <span id={FooterStyles.span}>TSS Outreach</span>
              </li>
              <li>
                <Link to="/programs/tss-adirondack">TSS Adirondacks</Link>
              </li>
              <li>
                <Link to="/programs/tss-athens">TSS Athens</Link>
              </li>
              <li>
                <span id={FooterStyles.span}>
                  <Link to="/programs/tss-research">TSS Research</Link>
                </span>
              </li>
            </ul>
          </div>

          <div className={FooterStyles.footerDivInner}>
            <h3>Address</h3>
            <ul>
              <li className={FooterStyles.address}>
                <img src={MarkerIcon}></img>
                <p>177 Chinquapin Way, Athens, GA 30605</p>
              </li>
              <li>
                <img src={PhoneIcon}></img>
                <p>(706) 614-7534</p>
              </li>
              <li>
                <img src={EmailIcon}></img>
                <p>admin@thesecretsits.org</p>
              </li>
              <li>
                <img src={EmailIcon}></img>
                <p>TSS@earthlink.net</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={FooterStyles.copyright}>
          <p>
            The Secret Sits Pro Musica is a national 501(c)(3) organization EIN
            © 2020 The Secret Sits Pro Musica. All Rights Reserved.
          </p>
        </div>
      </footer>
    );
  }
}
export default Footer;
